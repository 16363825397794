import React, { useContext, useState } from 'react'

import GenericDetailPage from '@templates/Generic/Detail'
import { wasSuccess } from '@hooks/useApi'
import { Card, KeyValueTable, TextField, WithCaution } from '@components'
import {
  Avatar,
  Box,
  ButtonGroup,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import { Delete } from '@mui/icons-material'
import useForm from '@hooks/useForm'
import moment from 'moment'
import { Skeleton } from '@mui/material'
import LicenseContext from '@utils/contexts/LicenseContext'

const LicensePage = () => {
  const { loading, license, addLicense, updateLicense, deleteLicense, error } =
    useContext(LicenseContext)
  const [saved, setSaved] = useState<boolean>(false)

  const noLicense = !loading && !license

  const form = useForm({
    initialValues: {
      license_key: ''
    }
  })

  const handleDelete = () => {
    deleteLicense().then((res) => {
      if (wasSuccess(res)) {
        setSaved(false)
      }
    })
  }

  const handleSave = () => {
    noLicense
      ? addLicense(form.values).then((res) => {
          if (wasSuccess(res)) {
            setSaved(false)
          }
        })
      : updateLicense(form.values).then((res) => {
          if (wasSuccess(res)) {
            setSaved(false)
          }
        })
  }

  return (
    <GenericDetailPage
      title={'License'}
      maxWidth="md"
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Host Actions"
        >
          <WithCaution>
            <Button
              data-cy="deleteLicense"
              disabled={loading || noLicense}
              endIcon={<Delete />}
              onClick={handleDelete}
            >
              Delete License
            </Button>
          </WithCaution>
        </ButtonGroup>
      }
      breadcrumbs={[
        {
          title: 'Settings',
          to: '/settings/'
        },
        {
          title: 'License'
        }
      ]}
    >
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item sm={8}>
          <Card
            heading="License Key"
            actions={
              <Button
                data-cy="licenseSubmit"
                variant="contained"
                color="primary"
                disabled={form.values.license_key === '' || saved}
                onClick={handleSave}
              >
                {saved ? 'Saved' : 'Update'}
              </Button>
            }
          >
            <Stack px={2} py={3} spacing={3}>
              <TextField
                disabled={saved}
                id="license_key"
                label="Enter License Key"
                placeholder="Place key here..."
                multiline
                rows={12}
                form={form}
              />
            </Stack>
          </Card>
        </Grid>

        <Grid item sm={4}>
          <Card
            heading="License Summary"
            color={noLicense && !loading ? 'error' : 'default'}
          >
            {license && !error && (
              <>
                <Box padding={2}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: '100%' }}
                  >
                    {loading ? (
                      <Grid item>
                        <Avatar
                          sx={{
                            m: '0 auto',
                            mb: 1,
                            fontSize: '3rem',
                            width: (theme) => theme.spacing(10),
                            height: (theme) => theme.spacing(10),
                            bgcolor: 'primary.main',
                            color: 'common.white'
                          }}
                        >
                          {'?'}
                        </Avatar>
                        <Typography variant="subtitle1" align="center">
                          {<Skeleton width="150" />}
                        </Typography>

                        <Typography variant="body2" align="center">
                          <strong>Website: </strong>
                          {
                            <Skeleton
                              style={{ display: 'inline-block', width: 80 }}
                              width="80"
                            />
                          }
                        </Typography>
                        <Typography variant="body2" align="center">
                          <strong>Email: </strong>
                          {
                            <Skeleton
                              style={{ display: 'inline-block', width: 80 }}
                              width="80"
                            />
                          }
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item>
                        <Avatar
                          sx={{
                            m: '0 auto',
                            mb: 1,
                            fontSize: '3rem',
                            width: (theme) => theme.spacing(10),
                            height: (theme) => theme.spacing(10),
                            bgcolor: 'primary.main',
                            color: 'common.white'
                          }}
                        >
                          {(license &&
                            license.customer.name.charAt(0).toUpperCase()) ||
                            ''}
                        </Avatar>
                        <Typography variant="subtitle1" align="center">{`${
                          license && license.customer.name
                        }`}</Typography>

                        <Typography
                          variant="body2"
                          align="center"
                          sx={{ wordBreak: 'break-word' }}
                        >
                          <strong>Website: </strong>
                          <span data-cy="licenseWebsite">
                            {` ${license && license.customer.website}`}
                          </span>
                        </Typography>
                        <Typography
                          variant="body2"
                          align="center"
                          sx={{ wordBreak: 'break-word' }}
                        >
                          <strong>Email: </strong>
                          <span data-cy="licenseEmail">
                            {` ${license && license.customer.email}`}
                          </span>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </Box>
                <Divider />
                <KeyValueTable
                  loading={false}
                  dataPoints={[
                    ['Host limit', license && license.limits.hosts],
                    [
                      'Issued',
                      moment(license && license.date.issued).format(
                        'D MMM YYYY'
                      )
                    ],
                    [
                      'Expires',
                      moment(license && license.date.expiry).format(
                        'D MMM YYYY'
                      )
                    ]
                  ]}
                />
              </>
            )}

            {error && (
              <Box p={2} data-cy="licenseStatus">
                <Typography>{error.detail}</Typography>
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>
    </GenericDetailPage>
  )
}

export default LicensePage
